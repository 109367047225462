.google-oauth-btn {
    color: #fff;
    background-color: #5569ff;
    box-shadow: none;
    font-weight: bold;
    text-transform: none;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    border-color: rgb(255, 255, 255);
    vertical-align: middle;
    padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 6px;
    padding-left: 20px;
    margin-bottom: 15px;
    margin-left: 12px;
    font-family: 'Inter';
    font-weight: bold !important;
    display: inline-flex;
}

.google-oauth-btn span {
    margin-right: 8px;
    margin-left: -4px;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.75;
    font-weight: bold;
    text-transform: none;
    color: #fff;
    text-align: center;
    letter-spacing: normal;
    word-spacing: normal;
    box-sizing: border-box;
    display: flex;
}

.google-oauth-btn svg {
    font-size: 20px;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    font-weight: bold;
    text-transform: none;
}